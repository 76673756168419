import React from 'react';

export default function StepFive() {
    const fileUrl = process.env.PUBLIC_URL + '/7_Punkte_Leitfaden_für_Ihren_reibungslosen_Wechsel_der_Kanzleisoftware.pdf';
  
    function handleDownload() {
      window.parent.postMessage("pdf-heruntergeladen", "*");
      fetch(fileUrl)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', '7_Punkte_Leitfaden_für_Ihren_reibungslosen_Wechsel_der_Kanzleisoftware.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
    }

    return (
        <div className="thanks-container">
            <div className="thanks-child-container">
                <p>Vielen Dank für Ihre Angaben. Wir haben analysiert, welche Software am besten zu Ihrer Kanzlei passt. Der Anbieter wird unverbindlich innerhalb von 24 Stunden Kontakt zu Ihnen aufnehmen.</p>
            </div>
            <div className='icon-parent-container last-page'>
                <div className="icon-container">
                    <img decoding="async" src={process.env.PUBLIC_URL + "/adplan.png"} alt="" />
                    <span>Jetzt 7 Punkte Leitfaden für Ihren reibungslosen Software-Wechsel als PDF downloaden!</span>
                </div>
            </div>
            <button onClick={handleDownload} className='btn btn-primary'>
                <i className="fas fa-download"></i> PDF herunterladen
            </button>
        </div>
    )
}
