import React, { useState } from "react";
import mobil from "../mobil.png";
import usage from "../actual-usage.png";
import change from "../change.png"
import appleImg from "../apple.png"
import members from "../memebrs.png"

export default function StepTwo({
    currentStep,
    handleMobileUsage,
    handleSoftwareUntil,
    reasonUntilStep,
    amountPeople,
    amountPeopleValue,
    appleUsage,
    apple,
    reasonUntil,
    handleTransferData,
    setSoftwareUntil,
    setReasonUntil,
    updateMobileUsage,
    updateSoftwareUntil,
    updateTransferData,
}) {

    const [initialMessage, setInitialMessage] = useState('Ihr Grund...');


    const handleTextareaChangeInput = (event) => {
        setReasonUntil(event.target.value);
    };

    const handleTextareaFocus = () => {
        if (reasonUntil === initialMessage) {
            setReasonUntil('');
        }
    };

    const handleTextareaBlur = () => {
        if (reasonUntil.trim() === '') {
         
        }
    };




    return (
        <>
            <div className={`step-container four ${currentStep === 4 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Warum möchten Sie wechseln?</h6>
                    <div className="image-container">
                        <img src={change} alt="Page" className={currentStep === 4 ? "image-active" : ""}/>
                    </div>
                    <div className="input-container">
                        <input
                            type="textarea"
                            id="fname"
                            name="fname"
                            placeholder={initialMessage}
                            className="form-input"
                            value={reasonUntil}
                            onChange={handleTextareaChangeInput}
                            onFocus={handleTextareaFocus}
                            onBlur={handleTextareaBlur}
                        ></input>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSoftwareUntil}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
            <div className={`step-container five ${currentStep === 5 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Arbeiten Sie mit Apple-Geräten?</h6>
                    <div className="image-container">
                        <img src={appleImg} alt="Page" className={currentStep === 5 ? "image-active" : ""}/>
                    </div>
                    <div className="button-container">
                        <button
                            type="button"
                            className={`btn btn-primary ${apple === true ? "green" : ""}`}
                            onClick={() => appleUsage(true)}
                        >
                            Ja
                        </button>
                        <button
                            type="button"
                            className={`btn btn-primary ${apple === false ? "red" : ""}`}
                            onClick={() => appleUsage(false)}
                        >
                            Nein
                        </button>
                    </div>
                </div>
            </div>

            <div className={`step-container six ${currentStep === 6 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Für wie viele Arbeitsplätze möchten Sie die Kanzleisoftware?</h6>
                    <div className="image-container">
                        <img src={members} alt="Page" className={currentStep === 6 ? "image-active" : ""}/>
                    </div>
                    <div className="button-container input-people">
                        <div className="input-label-container">
                            <label>
                                1
                            </label>
                            <input type="number" min="1" max="100" step="1.0" value={amountPeopleValue} onChange={(e) => amountPeople(e.target.value)}></input>
                            <label>
                                100
                            </label>
                        </div>
                        <input type="range" min="1" max="100" step="1.0" value={amountPeopleValue} onChange={(e) => amountPeople(e.target.value)}></input>
                    </div>
                </div>
            </div>
        </>
    )
}
