import React, { useState } from 'react';

export default function StepFour({
    mobileUsage,
    softwareUntil,
    transferData,
    reasonUntil,
    appleUsage,
    amountPeopleValue,
    softwareSelection,
    time,
    message,
    kanzlei,
    setKanzlei,
    email,
    phone,
    partner,
    setEmail,
    setPhone,
    setPartner,
    checkOne,
    checkTwo,
    checkThree,
    checkFour,
    setCheckOne,
    setCheckTwo,
    setCheckThree,
    setCheckFour

}) {

    const [initialMessage, setInitialMessage] = useState('Ihre Email...');
    const [initialMessagePhone, setInitialMessagePhone] = useState('Ihre Rufnummer...');
    const [initialMessagePartner, setInitialMessagePartner] = useState('Ihr Vor- und Nachname...');
    const [initialMessageKanzlei, setInitialMessageKanzlei] = useState('Ihr Kanzleiname..');

    const handleTextareaChangeInput = (event) => {
        setEmail(event.target.value);
    };

    const handleTextareaFocus = () => {
        if (email === initialMessage) {
            setEmail('');
        }
    };

    const handleTextareaBlur = () => {
        if (email.trim() === '') {

        }
    };



    const handleTextareaChangeInputPhone = (event) => {
        setPhone(event.target.value);
    };

    const handleTextareaFocusPhone = () => {
        if (phone === initialMessagePhone) {
            setPhone('');
        }
    };

    const handleTextareaBlurPhone = () => {
        if (phone.trim() === '') {

        }
    };

    const handleTextareaChangeInputKanzlei = (event) => {
        setKanzlei(event.target.value);
    };

    const handleTextareaFocusKanzlei = () => {
        if (kanzlei === initialMessageKanzlei) {
            setKanzlei('');
        }
    };

    const handleTextareaBlurKanzlei = () => {
        if (kanzlei.trim() === '') {

        }
    };


    const handleTextareaChangeInputPartner = (event) => {
        setPartner(event.target.value);
    };

    const handleTextareaFocusPartner = () => {
        if (partner === initialMessagePartner) {
            setPartner('');
        }
    };

    const handleTextareaBlurPartner = () => {
        if (partner.trim() === '') {

        }
    };

    const handleCheckboxChangeOne = (event) => {
        setCheckOne(event.target.checked);
    };

    const handleCheckboxChangeTwo = (event) => {
        setCheckTwo(event.target.checked);
    };

    const handleCheckboxChangeThree = (event) => {
        setCheckThree(event.target.checked);
    };

    const handleCheckboxChangeFour = (event) => {
        setCheckFour(event.target.checked);
    };




    return (
        <div className="submit-parent-container">
            <h2>Fast geschafft! Vielen Dank für Ihre Angaben – wir haben eine klare Empfehlung für Sie. </h2>
            <div className="submit-container">
                {/* <ul>
                    <li>Ihre individuelle Kanzleisoftware-Empfehlung</li>
                    <li>Ihr kostenloses E-Book Kanzleisoftware</li>
                </ul> */}
                <div className='icon-parent-container'>
                    <div className="icon-container-main">
                        <span>Erhalten Sie ...</span>
                    </div>
                    <div className="icon-container">
                        <img decoding="async" src="check.png" />
                        <span>Ihre persönliche Kanzleisoftware-Empfehlung</span>
                    </div>
                    <div className="icon-container">
                        <img decoding="async" src="adplan.png" />
                        <span>Unseren 7 Punkte Leitfaden für Ihren reibungslosen Software-Wechsel als PDF</span>
                    </div>
                </div>






                <div className='form-input'>
                    <div className='input-field-container'>
                        <div className='input-field-child'>
                            <label htmlFor="kanzlei">Kanzleiname<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="kanzlei" name="Kanzleiname" required
                                onChange={handleTextareaChangeInputKanzlei}
                                onFocus={handleTextareaFocusKanzlei}
                                onBlur={handleTextareaBlurKanzlei}
                                value={kanzlei}
                                placeholder={initialMessageKanzlei}
                            />
                        </div>
                        <div className='input-field-child'>
                            <label htmlFor="partner">Ansprechpartner<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="partner" name="Ansprechpartner" required
                                onChange={handleTextareaChangeInputPartner}
                                onFocus={handleTextareaFocusPartner}
                                onBlur={handleTextareaBlurPartner}
                                value={partner}
                                placeholder={initialMessagePartner}
                            />
                        </div>
                        <div className='input-field-child'>
                            <label htmlFor="mail">E-Mail<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="mail" name="E-Mail" required
                                onChange={handleTextareaChangeInput}
                                onFocus={handleTextareaFocus}
                                onBlur={handleTextareaBlur}
                                value={email}
                                placeholder={initialMessage} />
                        </div>
                        
                        {/* <div className='input-field-child'>
                            <label htmlFor="phone">Rufnummer<span style={{ color: 'red' }}>*</span></label>
                            <input type="tel" id="phone" name="Rufnummer" pattern="^\+?\d{0,15}$" required
                                onChange={handleTextareaChangeInputPhone}
                                onFocus={handleTextareaFocusPhone}
                                onBlur={handleTextareaBlurPhone}
                                value={phone}
                                placeholder={initialMessagePhone}
                            />
                        </div> */}
                    </div>
                    <div className='check-input datenschutz'>
                        <input
                            type="checkbox" id="check-policy" name="check-policy" required />
                        <label htmlFor="check-policy">Hiermit bestätige ich, dass ich die
                            <a target="_blank" href="https://legal-tech-check.de/datenschutz/"> Datenschutzerklärung </a>
                            zur Kenntnis genommen habe.<span style={{ color: 'red' }}>*</span></label>
                    </div>


                    <div className='check-input disclaimer'>
                        <input
                            type="checkbox" id="check-disclaimer" name="check-disclaimer" required />
                            <label htmlFor="check-disclaimer">
                            Mit Klick auf den Button „Abschicken“ willige ich ein, dass ich künftig telefonisch und per elektronischer Post über die Produkte der adojo GmbH informiert werde. Meine Daten dürfen hierfür verarbeitet werden. Diese Einwilligung gilt unabhängig davon, ob ein Vertrag besteht. Ich kann sie jederzeit formlos für die Zukunft widerrufen.
                            <span style={{ color: 'red' }}>*</span></label>
                    </div>

                    
                    {/* <label htmlFor="check">Möchten Sie darüber hinaus:</label>
                    <div className='check-input'>
                        <input checked={checkOne}
                            onChange={handleCheckboxChangeOne} type="checkbox" id="check1" name="Infos zu aktuellen Bundles oder Preis-Aktionen" />
                        <label htmlFor="check1">Infos zu aktuellen Bundles oder Preis-Aktionen</label>
                    </div>
                    <div className='check-input'>
                        <input checked={checkTwo}
                            onChange={handleCheckboxChangeTwo} type="checkbox" id="check2" name="Eine Live-Präsentation für Ihre Kanzlei" />
                        <label htmlFor="check2">Eine Live-Präsentation für Ihre Kanzlei</label>
                    </div>
                    <div className='check-input'>
                        <input checked={checkThree}
                            onChange={handleCheckboxChangeThree} type="checkbox" id="check3" name="Eine Beratung" />
                        <label htmlFor="check3">Eine Beratung</label>
                    </div>
                    <div className='check-input'>
                        <input checked={checkFour}
                            onChange={handleCheckboxChangeFour} type="checkbox" id="check4" name="Ein Angebot" />
                        <label htmlFor="check4">Ein Angebot</label>
                    </div> */}
                    <input type="hidden" name="Mobile Verwendung" value={mobileUsage} />
                    <input type="hidden" name="Bisherige Software" value={softwareUntil} />
                    <input type="hidden" name="Datenübertragung" value={transferData} />
                    <input type="hidden" name="Grund des Wechsels" value={reasonUntil} />
                    <input type="hidden" name="Verwendung von Apple" value={appleUsage} />
                    <input type="hidden" name="Anzahl der Mitarbeiter" value={amountPeopleValue} />
                    <input type="hidden" name="Cloud/Server Lösung" value={softwareSelection} />
                    <input type="hidden" name="Zeitraum des Wechsels" value={time} />
                    <input type="hidden" name="Weitere Anforderungen" value={message} />
                </div>
            </div>
        </div >
    );
}
