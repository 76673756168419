import React, { useState } from 'react';
import Page from './Page';
import StepFive from './page-components/StepFive';
import { useForm, ValidationError } from '@formspree/react';
import axios from "axios";

export default function Form({ step, setStep }) {
  const [state, handleSubmit] = useForm("mvonyplv");

  const [message, setMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [mobileUsage, setMobileUsage] = useState("");
  const [softwareUntil, setSoftwareUntil] = useState("");
  const [transferData, setTransferData] = useState("");
  const [reasonUntil, setReasonUntil] = useState("");
  const [amountPeopleValue, setAmountPeopleValue] = useState(30);
  const [softwareSelection, setSoftwareSelection] = useState("");
  const [time, setTime] = useState("");
  const [appleUsage, setAppleUsage] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [partner, setPartner] = useState("");
  const [kanzlei, setKanzlei] = useState("");
  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);
  const [checkThree, setCheckThree] = useState(false);
  const [checkFour, setCheckFour] = useState(false);

  if (state.succeeded) {
    window.parent.postMessage("konfigurator-gesendet", "*");
    return <StepFive />;
  }

  const sendToEmailServer = (event) => {

    event.preventDefault();
    setCurrentStep(currentStep + 1);
    setStep(currentStep + 1);
    axios.post('https://node-mailer-legal-tech-checkl-de.onrender.com/email', {
            Kanzleiname: `${kanzlei}`,
            Ansprechpartner: `${partner}`,
            EMail: `${email}`,
            TeleNr: `${phone}`,
            PreisAktionenBundles: `${checkOne}`,
            LivePresentation: `${checkTwo}`,
            Beratung: `${checkThree}`,
            Angebot: `${checkFour}`,
            MobileVerwendung: `${mobileUsage}`,
            SoftwareNutzung: `${softwareUntil}`,
            ServerSelection: `${softwareSelection}`,
            TimeSelection: `${time}`,
            TransferData: `${transferData}`,
            AmountPeople: `${amountPeopleValue}`,
            AppleUsage: `${appleUsage}`,
            WeitereAnforderungen: `${message}`,
            GrundDesWechsels: `${reasonUntil}`
            
        })
            .then(function (response) {

                console.log(response);

            })
            .catch(function (error) {

                console.log(error);

            });
  
 };

  return (
    <div className='main-div'>
      <form onSubmit={sendToEmailServer}>
        <Page
          state={state}
          step={step}
          setStep={setStep}
          message={message}
          setMessage={setMessage}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          mobileUsage={mobileUsage}
          setMobileUsage={setMobileUsage}
          softwareUntil={softwareUntil}
          setSoftwareUntil={setSoftwareUntil}
          transferData={transferData}
          setTransferData={setTransferData}
          reasonUntil={reasonUntil}
          setReasonUntil={setReasonUntil}
          amountPeopleValue={amountPeopleValue}
          setAmountPeopleValue={setAmountPeopleValue}
          softwareSelection={softwareSelection}
          setSoftwareSelection={setSoftwareSelection}
          time={time}
          setTime={setTime}
          appleUsage={appleUsage}
          setAppleUsage={setAppleUsage}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          partner={partner}
          setPartner={setPartner}
          kanzlei={kanzlei}
          setKanzlei={setKanzlei}
          checkOne={checkOne}
          setCheckOne={setCheckOne}
          checkTwo={checkTwo}
          setCheckTwo={setCheckTwo}
          checkThree={checkThree}
          setCheckThree={setCheckThree}
          checkFour={checkFour}
          setCheckFour={setCheckFour}
        />
      </form>
    </div>
  );
}
