import logo from './logo.svg';
import './App.css';
import Configurator from './components/Configurator';

function App() {
  return (
    <div className="App">
    <Configurator />
    </div>
  );
}

export default App;
