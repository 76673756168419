import React, { useState, useEffect } from 'react';
import Form from './configurator-components/Form';

export default function Configurator() {
  const [step, setStep] = useState(1);
  const [parentUrl, setParentUrl] = useState(null);

  useEffect(() => {
    window.parent.postMessage(`step-${step}`, '*');
    console.log(step);
  }, [step]);

  useEffect(() => {
    // Eine Funktion zum Empfangen von Nachrichten
    function receiveMessage(event) {

      setParentUrl(event.data.url);
      console.log(event.data.url);
    }

    // Fügen Sie den EventListener hinzu, wenn die Komponente gemountet wird
    window.addEventListener('message', receiveMessage, false);

    // Entfernen Sie den EventListener, wenn die Komponente unmountet wird
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  const headingClass = parentUrl && new URL(parentUrl).pathname !== '/' ? 'black-heading' : '';


  return (

    <div className='configurator-container'>
      {step < 10 && (
        <h2 className={`configurator-heading ${headingClass}`}>
          Kanzleisoftware-Finder
        </h2>
      )}
      <Form step={step} setStep={setStep} />
    </div>

  );
}
