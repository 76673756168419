import React, { useState } from "react";
import mobil from "../mobil.png";
import usage from "../actual-usage.png";
import datenmitnahme from "../daten-mitnahme.png";


export default function StepOne({
    currentStep,
    setCurrentStep,
    handleMobileUsage,
    handleSoftwareUntil,
    handleTransferData,
    setSoftwareUntil,
    mobilState,
    transferData,
    softwareUntil,
    updateMobileUsage,
    updateSoftwareUntil,
    updateTransferData
}) {

    const [initialMessage, setInitialMessage] = useState('Name der Software...');


    const handleTextareaChangeInput = (event) => {
        setSoftwareUntil(event.target.value);
    };

    const handleTextareaFocus = () => {
        if (softwareUntil === initialMessage) {
            setSoftwareUntil('');
        }
    };

    const handleTextareaBlur = () => {
        if (softwareUntil.trim() === '') {
         
        }
    };


    return (
        <div className="crit-container">
            <div className={`step-container one ${currentStep === 1 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Möchten Sie auch mobil auf Akten zugreifen?</h6>
                    <div className="image-container">
                        <img src={mobil} alt="Page"  className={currentStep === 1 ? "image-active" : ""} />
                    </div>
                    <div className="button-container">
                        <button
                            type="button"
                            className={`btn btn-primary ${mobilState === true ? "green" : ""}`}
                            onClick={() => handleMobileUsage(true)}
                        >
                            Ja
                        </button>
                        <button
                            type="button"
                            className={`btn btn-primary ${mobilState === false ? "red" : ""}`}
                            onClick={() => handleMobileUsage(false)}
                        >
                            Nein
                        </button>
                    </div>
                </div>
            </div>
            <div className={`step-container two ${currentStep === 2 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Welche Kanzleisoftware setzen Sie jetzt ein? </h6>
                    <div className="image-container">
                        <img src={usage} alt="Page" className={currentStep === 2 ? "image-active" : ""}/>
                    </div>
                    <div className="input-container">
                        <input
                            type="text"
                            id="fname"
                            name="fname"
                            placeholder={initialMessage}
                            className="form-input"
                            value={softwareUntil}
                            onChange={handleTextareaChangeInput}
                            onFocus={handleTextareaFocus}
                            onBlur={handleTextareaBlur}
                        ></input>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className={`btn btn-primary`}
                            onClick={handleSoftwareUntil}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
            <div className={`step-container three ${currentStep === 3 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Möchten Sie eine Datenübernahme aus Ihrer bisherigen Software?</h6>
                    <div className="image-container">
                        <img src={datenmitnahme} alt="Page" className={currentStep === 3 ? "image-active" : ""}/>
                    </div>
                    <div className="button-container">
                        <button
                            type="button"
                            className={`btn btn-primary ${transferData === true ? "green" : ""}`}
                            onClick={() => handleTransferData(true)}
                        >
                            Ja
                        </button>
                        <button
                            type="button"
                            className={`btn btn-primary ${transferData === false ? "red" : ""}`}
                            onClick={() => handleTransferData(false)}
                        >
                            Nein
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
