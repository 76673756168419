import React, { useState } from "react";
import mobil from "../mobil.png";
import usage from "../actual-usage.png";
import cloud from "../cloud.png";
import time from "../time.png";

export default function StepThree({
    currentStep,
    handleMobileUsage,
    handleSoftwareUntil,
    reasonUntilStep,
    amountPeople,
    amountPeopleValue,
    handleDropdownSelection,
    handleDropdownSelectionTwo,
    serverSelection,
    timeSelection,
    softwareSelection,
    handleTextareaChange,
    message,
    setMessage,
    timeValue,
    handleTransferData,
    setSoftwareUntil,
    updateMobileUsage,
    updateSoftwareUntil,
    updateTransferData,
}) {

    const [initialMessage, setInitialMessage] = useState('Ihre Anforderungen...');

    const handleTextareaChangeInput = (event) => {
        setMessage(event.target.value);
    };

    const handleTextareaFocus = () => {
        if (message === initialMessage) {
            setMessage('');
        }
    };

    const handleTextareaBlur = () => {
        if (message.trim() === '') {
           
        }
    };

    return (
        <>
            <div className={`step-container seven ${currentStep === 7 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Möchten Sie die Software in der Cloud, oder für Ihren eigenen Server?</h6>
                    <div className="image-container">
                        <img src={cloud} alt="Page" className={currentStep === 7 ? "image-active" : ""}/>
                    </div>
                    <div className="input-container">
                        <select value={softwareSelection} onChange={(e) => handleDropdownSelection(e.target.value)}>
                            <option hidden value >Bitte Auswählen</option>
                            <option value="Hochsicherheits-Cloud für Juristen in Deutschland">Hochsicherheits-Cloud für Juristen in Deutschland</option>
                            <option value="Eigener Server">Eigener Server</option>
                            <option value="Steht noch nicht fest">Steht noch nicht fest</option>
                        </select>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={serverSelection}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>

            <div className={`step-container eight ${currentStep === 8 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Wie schnell würden Sie zu einer neuen Software wechseln?</h6>
                    <div className="image-container">
                        <img src={time} alt="Page" className={currentStep === 8 ? "image-active" : ""}/>
                    </div>
                    <div className="input-container">
                        <select value={timeValue} onChange={(e) => handleDropdownSelectionTwo(e.target.value)}>
                            <option hidden value >Bitte Auswählen</option>
                            <option value="Sofort bis zwei Monate">Sofort bis zwei Monate</option>
                            <option value="Drei bis sechs Monate">Drei bis sechs Monate</option>
                            <option value="Steht noch nicht fest">Steht noch nicht fest</option>
                        </select>
                    </div>
                    <div className="button-container confirm">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={timeSelection}
                        >
                           Ok
                        </button>
                    </div>
                </div>
            </div>

            <div className={`step-container nine ${currentStep === 9 ? "" : "disabled"}`}>
                <div className="container-question">
                    <h6>Haben Sie weitere Anforderungen?</h6>
                    <div className="image-container">
                        <img src={mobil} alt="Page" className={currentStep === 9 ? "image-active" : ""}/>
                    </div>
                    <div className="button-container input-wish">

                        <textarea id="w3review" name="w3review" rows="4" cols="50"
                            onChange={handleTextareaChangeInput}
                            onFocus={handleTextareaFocus}
                            onBlur={handleTextareaBlur}
                            value={message}
                            placeholder={initialMessage}>
                        </textarea>
                    </div>
                </div>
            </div>
        </>
    )

}
