import React, { useEffect, useState } from "react";
import StepOne from "./page-components/StepOne";
import StepTwo from "./page-components/StepTwo";
import StepThree from "./page-components/StepThree";
import StepFour from "./page-components/StepFour";
import StepFive from "./page-components/StepFive";

export default function Page( {
  state,
  step,
  setStep,
  message,
  setMessage,
  currentStep,
  setCurrentStep,
  mobileUsage,
  setMobileUsage,
  softwareUntil,
  setSoftwareUntil,
  transferData,
  setTransferData,
  reasonUntil,
  setReasonUntil,
  amountPeopleValue,
  setAmountPeopleValue,
  softwareSelection,
  setSoftwareSelection,
  time,
  setTime,
  appleUsage,
  setAppleUsage,
  email,
  setEmail,
  phone,
  setPhone,
  partner,
  setPartner,
  kanzlei,
  setKanzlei,
  checkOne,
  setCheckOne,
  checkTwo,
  setCheckTwo,
  checkThree,
  setCheckThree,
  checkFour,
  setCheckFour,
} ) {
  

  const handleMobileUsage = (value) => {
    setMobileUsage(value);
    handleNext();
      // setCurrentStep(currentStep + 1);
  };


  const handleTextareaChange = (event) => {
    setMessage(event.target.value);
  };

  const handleAppleUsage = (value) => {
    setAppleUsage(value);
    handleNext();
    // setCurrentStep(currentStep + 1);
  };

  const handleSoftwareUntil = () => {
    if (softwareUntil) {
      handleNext();
    }
  };

  const handleServerSelection = () => {
    if (softwareSelection) {
      // setCurrentStep(currentStep + 1);
      handleNext();
    }
  };

  const handleTimeSelection = () => {
    if (time) {
      // setCurrentStep(currentStep + 1);
      handleNext(); 
    }
  };

  const handleTransferData = (value) => {
    setTransferData(value);
    handleNext();
    // setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    setStep(currentStep - 1);
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
    setStep(currentStep + 1);
  };

  const updateMobileUsage = (value) => {
    setMobileUsage(value);
  };

  const updateSoftwareUntil = (value) => {
    setSoftwareUntil(value);
  };

  const handleDropdownSelectionChange = (value) => {
    setSoftwareSelection(value);
  };

  const handleDropdownSelectionTwo = (value) => {
    setTime(value);
  };


  const amountPeople = (value) => {
    setAmountPeopleValue(value);
  };

  const updateTransferData = (value) => {
    setTransferData(value);
  };

  const reasonUntilStep = (value) => {
    setReasonUntil(value);
  };

 


  return (
    <div className="form-container">
      {currentStep < 10 && (
        <span className="text-percentage"> {currentStep}0% </span>
      )}
      <div className="step-parent-container">

        {currentStep < 4 && (
          <>
            <StepOne
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              handleMobileUsage={handleMobileUsage}
              handleSoftwareUntil={handleSoftwareUntil}
              handleTransferData={handleTransferData}
              setSoftwareUntil={setSoftwareUntil}
              updateMobileUsage={updateMobileUsage}
              updateSoftwareUntil={updateSoftwareUntil}
              updateTransferData={updateTransferData}
              reasonUntilStep={reasonUntilStep}
              mobilState={mobileUsage}
              softwareUntil={softwareUntil}
              transferData={transferData}

            />
          </>
        )}
        {currentStep > 3 && currentStep < 7 && (
          <>
            <StepTwo
              currentStep={currentStep}
              handleMobileUsage={handleMobileUsage}
              handleSoftwareUntil={handleSoftwareUntil}
              handleTransferData={handleTransferData}
              setSoftwareUntil={setSoftwareUntil}
              updateMobileUsage={updateMobileUsage}
              updateSoftwareUntil={updateSoftwareUntil}
              updateTransferData={updateTransferData}
              reasonUntil={reasonUntil}
              setReasonUntil={setReasonUntil}
              amountPeople={amountPeople}
              amountPeopleValue={amountPeopleValue}
              appleUsage={handleAppleUsage}
              apple={appleUsage}
              softwareUntil={softwareUntil}

            />
          </>
        )}
        {currentStep > 6 && currentStep < 10 && (
          <>
            <StepThree
              currentStep={currentStep}
              handleMobileUsage={handleMobileUsage}
              handleSoftwareUntil={handleSoftwareUntil}
              handleTransferData={handleTransferData}
              setSoftwareUntil={setSoftwareUntil}
              updateMobileUsage={updateMobileUsage}
              updateSoftwareUntil={updateSoftwareUntil}
              updateTransferData={updateTransferData}
              reasonUntilStep={reasonUntilStep}
              amountPeople={amountPeople}
              amountPeopleValue={amountPeopleValue}
              handleDropdownSelection={handleDropdownSelectionChange}
              serverSelection={handleServerSelection}
              timeSelection={handleTimeSelection}
              handleDropdownSelectionTwo={handleDropdownSelectionTwo}
              setMessage={setMessage}
              message={message}
              softwareSelection={softwareSelection}
              timeValue={time}
            />
          </>
        )}
        {currentStep > 9 && currentStep < 11 && (
          <>
            <StepFour
              currentStep={currentStep}
              mobileUsage={mobileUsage}
              softwareUntil={softwareUntil}
              transferData={transferData}
              reasonUntil={reasonUntil}
              appleUsage={appleUsage}
              amountPeopleValue={amountPeopleValue}
              softwareSelection={softwareSelection}
              time={time}
              message={message}

              handleTextareaChange={handleTextareaChange}
              handleMobileUsage={handleMobileUsage}
              handleSoftwareUntil={handleSoftwareUntil}
              handleTransferData={handleTransferData}
              setSoftwareUntil={setSoftwareUntil}
              updateMobileUsage={updateMobileUsage}
              updateSoftwareUntil={updateSoftwareUntil}
              updateTransferData={updateTransferData}
              reasonUntilStep={reasonUntilStep}
              amountPeople={amountPeople}
              handleDropdownSelection={handleDropdownSelectionChange}
              serverSelection={handleServerSelection}
              timeSelection={handleTimeSelection}
              handleDropdownSelectionTwo={handleDropdownSelectionTwo}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              partner={partner}
              setPartner={setPartner}
              checkOne={checkOne}
              setCheckOne={setCheckOne}
              checkTwo={checkTwo}
              setCheckTwo={setCheckTwo}
              checkThree={checkThree}
              setCheckThree={setCheckThree}
              checkFour={checkFour}
              setCheckFour={setCheckFour}
              kanzlei={kanzlei}
              setKanzlei={setKanzlei}
            />
          </>
        )}
        {currentStep > 10 && (
          <>
            <StepFive
              currentStep={currentStep}
              handleMobileUsage={handleMobileUsage}
              handleSoftwareUntil={handleSoftwareUntil}
              handleTransferData={handleTransferData}
              setSoftwareUntil={setSoftwareUntil}
              updateMobileUsage={updateMobileUsage}
              updateSoftwareUntil={updateSoftwareUntil}
              updateTransferData={updateTransferData}
              reasonUntilStep={reasonUntilStep}
              amountPeople={amountPeople}
              amountPeopleValue={amountPeopleValue}
              handleDropdownSelection={handleDropdownSelectionChange}
              serverSelection={handleServerSelection}
              timeSelection={handleTimeSelection}
              handleDropdownSelectionTwo={handleDropdownSelectionTwo}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              partner={partner}
              setPartner={setPartner}
            />
          </>
        )}
      </div>
      <div className="button-container-main">
        <div className="button-container-child">
          {currentStep > 1 && currentStep < 11 && (
            <button type="button" className="btn btn-primary" onClick={handlePrevious}>
              Zurück
            </button>
          )}
          {currentStep < 10 && (
            <button type="button" className="btn btn-primary" onClick={handleNext} disabled={currentStep === 1 && (mobileUsage === null || softwareUntil === "" || transferData === null)}>
              Weiter
            </button>
          )}

          {currentStep > 9 && currentStep < 11 && (
            <button className="submit" type="submit"  disabled={state.submitting}>
              Senden
            </button>
          )}

        </div>
      </div>
    </div>
  );
}
